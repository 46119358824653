import React, { ReactElement } from 'react'

// TODO: Finish this step.
export const Step2 = (): ReactElement => (
  <>
    <p className="my-4">
      Instead of simply following the mouse, make the point oscillate around the mouse.
    </p>

    <p className="my-4">
      <i>Under construction...</i>
    </p>
  </>
)
